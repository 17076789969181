import React from "react";
import { EXTENSIONS } from "../../helpers/requests";
import PageTitle from "../generic/PageTitle";
import { useData, useEnsureUpToDateConfigs, useTenantNavigate } from "../../helpers/CustomHooks";
import LoadingMessage from "../generic/LoadingMessage";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomColumnNames, renderCustomRowCells } from "../../helpers/TableHelper";
import Context from "../../Store";
import { getEntityName, sortEntitiesOfTypeByName } from "../../helpers/StaticDataHelper";
import ApprovedEntitiesTable from "../tables/ApprovedEntitiesTable";
import { VIEW } from "../../App";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";


const ApprovedPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const {entity_types} = staticData;
    const navigate = useTenantNavigate();

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const parent_id = params.entity_type_instance_id ? Number(params.entity_type_instance_id) : null;
    
    const onDataFetched = (resp) => sortEntitiesOfTypeByName(resp.data.data)
    const data = useData(EXTENSIONS.GET_APPROVED_ENTITIES, {entity_type_id, parent_id}, onDataFetched);
    
    const awairedDatas = [configSyncState, data]
    if(awairedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awairedDatas.includes(null)) return <LoadingMessage/>;

    const isMembers = !!parent_id;

    const parentName = getEntityName(staticData, parent_id);
    const entityType = entity_types.find(et => et.id === entity_type_id);
    const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type;

    const entities = data.data;

    const structure = parent_id ? entityType.member_instance_type.structure : entityType.root_instance_type.structure;
    const title = `Approved ${entityInstanceType.name}s`;

    const columnDisplays = entityInstanceType.approved_table_display.table_column_displays
    const columnNames = getCustomColumnNames(entityInstanceType, columnDisplays);
    // const renderRowCells = getRenderRowCells(entityInstanceType, TABLE_DISPLAY_TYPE_KEYS.APPROVED, staticData, (e) => e.state.form_values);
    const renderRowCells = (e) => renderCustomRowCells(e, entityInstanceType, columnDisplays, staticData, e.state.form_values)

    const onRowClick = (entity) => {
        const urlExt = `/${VIEW}/entity_type/${entity_type_id}/eti/${entity.id}`
        navigate(urlExt);
    }

    return (
        <div key={`ApprovedPage-${entity_type_id}--${parent_id}`}>
            <PageTitle title={title} subtitle={parentName}/>
            <ApprovedEntitiesTable
                structure={structure}
                entities={entities}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                />
        </div>
    )

}

export default ApprovedPage;
