import React from "react";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import Context from "../../Store";
import { useData, useEnsureUpToDateConfigs, useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, downloadAccessFileField, post, postTicket } from "../../helpers/requests";
import { ACCESS_REQUEST_TYPES, getAccessInstanceContextValues, isAccessForUser, getAccessStructureCopyWithAccessLevels, isAccessTypeForEmp } from "../../helpers/access_helper";
import LoadingMessage from "../generic/LoadingMessage";
import { getDefaultValue } from "../../helpers/DefaultFormValueMaker";
import { Box, Stack, TextField, Divider } from "@mui/material";
import LoadButton from "../generic/LoadButton";
import { ACTION_MSG_MAX_LEN, ENTITY_REQUEST_TYPES, REQUEST_STATES, TICKET_STATUS } from "../../helpers/Constants";
import { CONTEXT_DEPENDENCIES, formatFormValues, makeNestedDeepCopy, makeTicketDeepCopy } from "../../helpers/TicketFormHelper";
import { validateForm } from "../../helpers/TicketFormValidationHelper";
import TicketView from "../instance/TicketView";
import CollapseableContent from "../generic/CollapseableContent";
import { getEntityInstanceTypeById, getEntityTypeWithInstanceTypeId } from "../../helpers/StaticDataHelper";
import TicketTextField from "../inputs/TicketTextField";
import AccessHeader from "./AccessHeader"
import AccessRelatedEntitiesStates from "./AccessRelatedEntitiesStates";
import { hasInstancePermissions } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import MultiSectionForm from "../form/MultiSectionForm";
import TicketParagraphField from "../inputs/TicketParagraphField";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";



const AccessGrantRequest = ({structure, accessInstance, refetchAccessInstance, accessType}) => {
    ///accessInstance, accessorId, resourceId, isEmpAccess can be derived from the instance
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);

    const isEmpAccess = isAccessTypeForEmp(accessType);
    const accessorId = accessInstance.accessor.id;
    const resourceId = accessInstance.resource.id;

    const [formData, setFormData] = React.useState(getDefaultValue(structure));
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [message, setMessage] = React.useState("");
    const isForUser = isAccessForUser(user, accessInstance, accessType);
    const canRequestGrant = isForUser || hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.REQ_GRANT.key, accessInstance, accessType)
    const canOverrideGrant = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_GRANT.key, accessInstance, accessType);

    const contextValues = getAccessInstanceContextValues(accessInstance);
    const onSubmitClick = (isOverride) => {
        if(!message){
            alertError('Must provide a note/message with the request');
            return;
        }
        if(message.length > ACTION_MSG_MAX_LEN){
            alertError('Your note/message is too long');
            return;
        }
        let formDataCopy = makeTicketDeepCopy(formData)
        formatFormValues(formDataCopy, structure)
        
        const errors = validateForm(formDataCopy, structure, true, contextValues)
        const hasErrors = (errors.length !== 0)
        
        const meta = {
            instance_type: "Access",
            request_type: ACCESS_REQUEST_TYPES.GRANT,
            accessor_id: accessorId,
            resource_id: resourceId,
            is_emp_access: isEmpAccess,
        }
        const body = {meta: meta, values: formDataCopy, is_override: isOverride, msg: message}
        
        if(hasErrors){
            let msg = "Please fix the following errors\n";
            msg += errors.join("\n")
            alertError(msg, null)
        }
        else{
            const onSuccess = (response) => {
                alertSuccess("Successfully processed your request");
                setSubmitState(REQUEST_STATES.SUCCEEDED);
                refetchAccessInstance();
            }
            const onFail = (e) => {
                alertError(e.response.data);
                setSubmitState(REQUEST_STATES.FAILED)
            }
            postTicket(EXTENSIONS.SUBMIT_TICKET, body, structure, onSuccess, onFail)
            setSubmitState(REQUEST_STATES.SENDING)
        }
    }

    if (!canRequestGrant && !canOverrideGrant){
        return (
            <Box>
                <LabelValueDisplay label={'Current access'} value={'None'}/>
                <LabelValueDisplay label={'Pending access'} value={'None'}/>
            </Box>
        )
    }
    return (
        <Box>
            <AccessHeader title={'Request Access'} accessInstance={accessInstance}/>
            <MultiSectionForm
                structure={structure}
                formData={formData}
                setFormData={setFormData}
                isInitialSubmission={true}
                editableSectionIds={"*"}
                contextValues={contextValues}
                />
            <Box sx={SX_BOX_SIMPLE}>
                <TicketParagraphField        
                    value={message}
                    setValue={setMessage}
                    maxLength={ACTION_MSG_MAX_LEN}
                    label="Notes / message"
                    fullWidth
                    />
                <Stack direction={'row'} spacing={2} marginX={'auto'} marginTop={4} justifyContent={'center'}>
                    {
                        !canRequestGrant ? null :
                        <LoadButton
                            variant="contained"
                            onClick={() => onSubmitClick(false)}
                            size="large"
                            loading={submitState === REQUEST_STATES.SENDING}
                            disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                            >
                            Submit
                        </LoadButton>
                    }
                    {
                        !canOverrideGrant ? null :
                        <LoadButton
                            variant="contained"
                            onClick={() => onSubmitClick(true)}
                            size="large"
                            loading={submitState === REQUEST_STATES.SENDING}
                            disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                            tooltip={`This will bypass any approval approval processes, and Access will be immediately recorded as granted`}
                            color={'error'}
                            >
                            Override
                        </LoadButton>
                    }
                </Stack>
            </Box>
        </Box>
    )
}

const PendingGrantRequestTicketView = ({pendingGrantRequestTicket, structure, accessInstance, refetchAccessInstance, accessType}) => {
    ///accessInstance, accessorId, resourceId, isEmpAccess can be derived from the instance
    const {staticData, alertSuccess, alertError, user} = React.useContext(Context);
    const {access_types, all_users} = staticData;
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    // const [message, setMessage] = React.useState("");
    const canUserCancelTickets = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.CANCEL_REQ.key, accessInstance, accessType);
    
    const isEmpAccess = isAccessTypeForEmp(accessType);
    const accessorId = accessInstance.accessor.id;
    const resourceId = accessInstance.resource.id;
    const contextValues = getAccessInstanceContextValues(accessInstance);

    const onCancelTicketClick = (message) => {
        if(!message){
            alertError("Provide a reason for cancling the request.");
            return;
        }
        const body = {
            instance_desc: {
                instance_type: "Access",
                accessor_id: accessorId,
                resource_id: resourceId,
                is_emp_access: isEmpAccess, 
            },
            ticket_id: pendingGrantRequestTicket.id,
            msg: message
        }
        const onSuccess = (response) => {
            alertSuccess("Request has been canceled");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchAccessInstance();
        }
        const onFail = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED)
        }
        post(EXTENSIONS.CANCEL_TICKET, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING)
    }

    const downloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadAccessFileField(isEmpAccess, accessInstance.accessor.id, accessInstance.resource.id, pendingGrantRequestTicket.id, sectionId, fieldId, null);
        }
    }

    return (
        <Box>
            <AccessHeader title={'Pending Access'} accessInstance={accessInstance}/>
            <TicketView
                ticket={pendingGrantRequestTicket}
                structure={structure}
                allUsersMap={all_users}
                onCancelClick={(t, msg) => onCancelTicketClick(msg)}
                submitState={submitState}
                canUserCancel={canUserCancelTickets}
                getSectionDownloadFileField={downloadFileGetter}
                contextValues={contextValues}
                />
        </Box>
    )
}

const EditAccess = ({structure, accessInstance, onCancelEditClick, refetchAccessInstance, accessType}) => {
    const {alertSuccess, alertError, user, staticData} = React.useContext(Context);

    const [formData, setFormData] = React.useState(makeTicketDeepCopy(accessInstance.state.form_values));
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [message, setMessage] = React.useState("");

    const isEmpAccess = isAccessTypeForEmp(accessType);

    const isAccessForSelf = isAccessForUser(user, accessInstance, accessType);
    const canUserRequestEdit = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.REQ_EDIT.key, accessInstance, accessType)
    const canUserOverrideEdit = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_EDIT.key, accessInstance, accessType);
    const canUserRequestRemoval = isAccessForSelf || hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.REQ_REMOVE.key, accessInstance, accessType);
    const canUserOverrideRemoval = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_REMOVE.key, accessInstance, accessType);
    
    const contextValues = getAccessInstanceContextValues(accessInstance);

    const onSaveEditClick = (isOverride) => {
        if(!message){
            alertError("Please provide a note or message for the edit.")
            return;
        }
        if(message.length > ACTION_MSG_MAX_LEN){
            alertError("Note / message for the edit is too long.")
            return;
        }
        let formDataCopy = makeTicketDeepCopy(formData)
        formatFormValues(formDataCopy, structure)
        
        const errors = validateForm(formDataCopy, structure, true, contextValues)
        const hasErrors = (errors.length !== 0)
        
        const body = {
            values: formDataCopy,
            is_override: isOverride,
            msg: message,
            instance_desc: {
                instance_type: "Access",
                is_emp_access: isEmpAccess,
                accessor_id: accessInstance.accessor.id,
                resource_id: accessInstance.resource.id,
            },
        }
        
        if(hasErrors){
            let msg = "Please fix the following errors\n";
            msg += errors.join("\n")
            alertError(msg, null)
        }
        else{
            const onSuccess = (response) => {
                alertSuccess("Successfully processed your request");
                setSubmitState(REQUEST_STATES.SUCCEEDED);
                refetchAccessInstance();
            }
            const onFail = (e) => {
                alertError(e.response.data);
                setSubmitState(REQUEST_STATES.FAILED)
            }
            postTicket(EXTENSIONS.EDIT_INSTANCE, body, structure, onSuccess, onFail)
            setSubmitState(REQUEST_STATES.SENDING)
        }
    }
    const onRemoveAccessClick = (isOverride) => {
        if(!message){
            alertError("Please provide a note regarding the access removal.")
            return;
        }
        if(message.length > ACTION_MSG_MAX_LEN){
            alertError("Note / message for is too long.")
            return;
        }
        const body = {
            meta: {
                instance_type: "Access",
                request_type: ACCESS_REQUEST_TYPES.REMOVE,
                is_emp_access: isEmpAccess,
                accessor_id: accessInstance.accessor.id,
                resource_id: accessInstance.resource.id,
            },
            is_override: isOverride,
            msg: message,
            values: {}
        }
        const onSuccess = (resp) => {
            const notifMsg = isOverride ? "Access removal recorded" : "Request submitted";
            alertSuccess(notifMsg);
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchAccessInstance();
        }
        const onFailure = (e) => {
            setSubmitState(REQUEST_STATES.FAILED);
            alertError(e.response.data);
        }
        const emptyStructure = {sections: []}
        postTicket(EXTENSIONS.SUBMIT_TICKET, body, structure, onSuccess, onFailure);
        setSubmitState(REQUEST_STATES.SENDING);
    }
    return (
        <Box>
            <AccessHeader title={'Manage Access'} accessInstance={accessInstance}/>
            <Button onClick={onCancelEditClick} variant="contained">
                Cancel
            </Button>
            <MultiSectionForm
                structure={structure}
                formData={formData}
                setFormData={setFormData}
                isInitialSubmission={true}
                editableSectionIds={"*"}
                contextValues={contextValues}
                />
            <Divider sx={{ borderBottomWidth: 2, marginY:2, bgcolor:'#000000' }}/>
            <Box sx={SX_BOX_SIMPLE} maxWidth={700} marginX={'auto'}>
                <Box m={1}>
                    <TicketParagraphField        
                        value={message}
                        setValue={setMessage}
                        maxLength={ACTION_MSG_MAX_LEN}
                        label="Notes / message"
                        fullWidth
                        m={10}
                        />
                </Box>
                <Stack direction={'row'} spacing={2} marginX={'auto'} marginY={1} justifyContent={'center'}>
                    {
                        !canUserRequestEdit ? null :
                        <LoadButton
                            variant="contained"
                            onClick={() => onSaveEditClick(false)}
                            size="large"
                            loading={submitState === REQUEST_STATES.SENDING}
                            disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                            >
                            Save
                        </LoadButton>
                    }
                    {
                        !canUserOverrideEdit ? null :
                        <LoadButton
                            variant="contained"
                            onClick={() => onSaveEditClick(true)}
                            size="large"
                            loading={submitState === REQUEST_STATES.SENDING}
                            disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                            tooltip={`This will bypass any approval approval processes, and immediately apply the edited values provided`}
                            color={'error'}
                            >
                            Override Save
                        </LoadButton>
                    }
                {/* </Stack> */}
                {/* <Stack direction={'row'} spacing={2} marginX={'auto'} marginTop={4} justifyContent={'center'} border={2} borderRadius={2}> */}
                {
                    !canUserRequestRemoval ? null :
                    <LoadButton
                        variant="contained"
                        onClick={() => onRemoveAccessClick(false)}
                        size="large"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        color={'error'}
                        >
                        Remove Access
                    </LoadButton>
                }
                {
                    !canUserOverrideRemoval ? null :
                    <LoadButton
                        variant="contained"
                        onClick={() => onRemoveAccessClick(true)}
                        size="large"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        tooltip={`This will bypass any approval approval processes, and the Access will be immediately recorded as removed`}
                        color={'error'}
                        >
                        Override Remove
                    </LoadButton>
                }
                </Stack>
            </Box>
        </Box>
    )
}

const ActiveAccessInstanceView = ({accessInstance, structure, accessType, refetchAccessInstance}) => {
    const {staticData, alertSuccess, alertError, user} = React.useContext(Context);
    const {all_users} = staticData;
    const [isInEditMode, setIsInEditMode] = React.useState(false);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const is_active = true;
    const isEmpAccess = isAccessTypeForEmp(accessType);
    const contextValues = getAccessInstanceContextValues(accessInstance);

    const onCancelTicketClick = (ticket, msg) => {
        if(!msg){
            alertError("Provide a note or reason for canceling the request.")
            return;
        }
        const body = {
            instance_desc: {
                instance_type: "Access",
                is_emp_access: isEmpAccess,
                accessor_id: accessInstance.accessor.id,
                resource_id: accessInstance.resource.id,
            },
            ticket_id: ticket.id,
            message: msg
        }
        const onSuccess = (resp) => {
            alertSuccess("Ticket canceled");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchAccessInstance();
        }
        const onFailure = (e) => {
            setSubmitState(REQUEST_STATES.FAILED);
            alertError(e.response.data);
        }
        post(EXTENSIONS.CANCEL_TICKET, body, onSuccess, onFailure);
        setSubmitState(REQUEST_STATES.SENDING);
    }

    const downloadFileGetter = (ticketId) => { 
        return (sectionId) => {
            return (fieldId) => {
                downloadAccessFileField(isEmpAccess, accessInstance.accessor.id, accessInstance.resource.id, ticketId, sectionId, fieldId, null);
            }
        }
    }
    if(isInEditMode){
        return(
            <EditAccess
                structure={structure}
                onCancelEditClick={() => setIsInEditMode(false)}
                accessInstance={accessInstance}
                refetchAccessInstance={refetchAccessInstance}
                accessType={accessType}
                />
        )
    }

    const isAccessForSelf = isAccessForUser(user, accessInstance, accessType);
    const canUserCancelTickets = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.CANCEL_REQ.key, accessInstance, accessType);
    const canUserRequestEdit = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.REQ_EDIT.key, accessInstance, accessType)
    const canUserOverrideEdit = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_EDIT.key, accessInstance, accessType);
    const canUserRequestRemoval = isAccessForSelf || hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_EDIT.key, accessInstance, accessType);
    const canUserOverrideRemoval = hasInstancePermissions(user, staticData, ALL_PERMS.ACCESS_ACTIONS.OVERRIDE_EDIT.key, accessInstance, accessType);
    const canUserManageAccess = canUserRequestEdit || canUserOverrideEdit || canUserRequestRemoval || canUserOverrideRemoval;

    const resourceAccessRemovalTickets = accessInstance.resource.tickets.filter(t => t.request_type === ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL);
    const isResourceAccessRemovalPending = resourceAccessRemovalTickets.some(t => t.status === TICKET_STATUS.PENDING);
    const doApprovedResourceRemovalTicketsExist = resourceAccessRemovalTickets.some(t => t.status === TICKET_STATUS.APPROVED);
    // const doesResourceHaveFullAccessRemovalTicket = accessInstance.resource.tickets.find(t => t.request_type === ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL);
    return (
        <Box>
            <AccessHeader title={'Current Access'} accessInstance={accessInstance}/>
            {
                !canUserManageAccess ? null :
                <Button onClick={()=>setIsInEditMode(!isInEditMode)} variant="contained" sx={{marginBottom: 2}}>
                    Manage
                </Button>
            }
            <CollapseableContent title={`Access details`} isExpandedByDefault={true}>
                <MultiSectionForm
                    formData={accessInstance.state.form_values}
                    structure={structure}
                    getSectionDownloadFileField={downloadFileGetter(null)}
                    contextValues={contextValues}
                    />
            </CollapseableContent>
            <CollapseableContent title={`Tickets`} isExpandedByDefault={false}>
                {
                    !isResourceAccessRemovalPending ? null :
                    <Typography>
                        *Note: this access is pending removal since {accessInstance.resource.name} is awaiting all access
                        to it to be removed. See resource panel for details.
                    </Typography>
                }
                {
                    !doApprovedResourceRemovalTicketsExist ? null :
                    <Typography>
                        *Note: this access has been previously removed when all access to {accessInstance.resource.name} was removed. See resource panel
                        for details.
                    </Typography>
                }
                {
                        accessInstance.tickets.map(t => {
                            const isPending = (t.status === "Pending");
                            const headerColor = isPending ? 'primary.light' : undefined;
                            const title = `${t.request_type} (${t.status})`;
                            return(
                                <CollapseableContent isExpandedByDefault={!is_active && isPending} key={t.id} headerColor={headerColor} title={title}>
                                    <TicketView
                                        ticket={t}
                                        structure={structure}
                                        allUsersMap={all_users}
                                        key={t.id}
                                        onCancelClick={onCancelTicketClick}
                                        submitState={submitState}
                                        canUserCancel={canUserCancelTickets}
                                        getSectionDownloadFileField={downloadFileGetter(t.id)}
                                        contextValues={contextValues}
                                        />
                                </CollapseableContent>
                            )
                        })
                }
            </CollapseableContent>
            <AccessRelatedEntitiesStates accessInstance={accessInstance} accessType={accessType}/>
        </Box>
    )
}


const AccessRequestPage2 = () => {
    const configSyncState = useEnsureUpToDateConfigs()
    const params = useParams();
    // access_type/:access_type_id/accessor/:accessor_id/resource/:resource_id
    const access_type_id = Number(params.access_type_id);
    const accessor_id = Number(params.accessor_id);
    const resource_id = Number(params.resource_id);

    const {staticData} = React.useContext(Context);
    const {access_types, all_users} = staticData;

    const accessType = access_types.find(at => at.id === access_type_id);
    const isEmpAccess = isAccessTypeForEmp(accessType); //TODO: body request params should depend on cached static data
    
    
    const body = {instance_desc: {instance_type: "Access", is_emp_access: isEmpAccess, accessor_id, resource_id}};
    const [accessInstance, refetchAccessInstance] = useRefetchData(EXTENSIONS.GET_INSTANCE, body);
    const [accessLevelsData, refetchAccessLevels] = useRefetchData(EXTENSIONS.GET_ACCESS_LEVELS, {resource_id: resource_id});

    
    const datas = [accessInstance, accessLevelsData, configSyncState];
    if(datas.includes(false)) return <div>Failed to load</div>;
    if(datas.includes(null)) return <LoadingMessage />;
    // const accessLevels = accessLevelsData.access_levels;
    
    //insert access levels to structure
    //the access level field does not come prepopulated, as the choices could grow very large
    //to anticipate all access levels for all resources, so it is populated when needed
    const structure = getAccessStructureCopyWithAccessLevels(accessType, accessLevelsData.access_levels);
    
    const {id, is_active, state, tickets, resource, accessor} = accessInstance;

    const pendingGrantRequest = tickets.find(t => t.status === "Pending" && t.request_type === ACCESS_REQUEST_TYPES.GRANT);
    const hasPendingGrantRequest = !!pendingGrantRequest;

    if(!is_active && !hasPendingGrantRequest){
        //show request form
        return <AccessGrantRequest
            structure={structure}
            accessInstance={accessInstance}
            refetchAccessInstance={refetchAccessInstance}
            accessType={accessType}
            />
            
    }
    else if(!is_active && hasPendingGrantRequest){
        //show pending request
        //allow cancel
        return <PendingGrantRequestTicketView
            pendingGrantRequestTicket={pendingGrantRequest}
            structure={structure}
            accessInstance={accessInstance}
            refetchAccessInstance={refetchAccessInstance}
            accessType={accessType}
            />

    }
    else if(is_active){
        //show current state
        //mention pending tickets
        //allow editig the state
        //allow requesting removal
        return <ActiveAccessInstanceView
            accessInstance={accessInstance}
            structure={structure}
            accessType={accessType}
            refetchAccessInstance={refetchAccessInstance}
            />
    }

}

export default AccessRequestPage2;